import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import { Link } from "@reach/router"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const RegistrationPage = ({ data }) => {
  const mainContentDocument = data?.contentfulRegistrationPage?.mainContent

  return (
    <div className="registration mx-auto  my-5 flex flex-col items-center base--shiftContent ">
      <SEO
        title="Registration"
        keywords={[`Sioux Lookout`, `hockey`, `Registration`, `info`]}
      />
      <h1 className="base__pageTitle">Registration</h1>

      <div
        className="mt-10 break-words"
        style={{ maxWidth: "80%", justifySelf: "center" }}
      >
        {renderRichText(mainContentDocument)}
      </div>

      <p style={{ textAlign: "center" }}>
        <Link to="/contact">Contact us</Link> for more information.
      </p>
    </div>
  )
}

export default RegistrationPage

export const pageQuery = graphql`
  query registrationQuery {
    registrationImg: file(relativePath: { eq: "sampleImage.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    registrationForm: contentfulDocument(
      title: { eq: "SLMHA 2020 2021 Season Registration" }
    ) {
      file {
        file {
          url
        }
      }
    }
    contentfulRegistrationPage {
      mainContent {
        raw
      }
    }
  }
`
